import { Heading, Link, VStack } from '@chakra-ui/react';
import React from 'react';

export const RETREAT_POPULAR_PAGES = [
  {
    title: 'All Retreats',
    url: '/retreats'
  },
  {
    title: 'Yoga Retreats',
    url: '/retreats/yoga'
  },
  {
    title: 'Wellness Retreats',
    url: '/retreats/health-and-wellness'
  },
  {
    title: 'Meditation Retreats',
    url: '/retreats/meditation'
  },
  {
    title: 'Yoga Teacher Training',
    url: '/retreats/yoga-teacher-training'
  },
  {
    title: `Women's Retreats`,
    url: '/retreats/women'
  },
  {
    title: 'Yoga Retreats in Bali',
    url: '/retreats/yoga/bali'
  },
  {
    title: 'Yoga Retreats in United Kingdom',
    url: '/retreats/yoga/united-kingdom'
  },
  {
    title: 'Yoga Retreats in Australia',
    url: '/retreats/yoga/australia'
  },
  {
    title: 'Yoga Retreats in Italy',
    url: '/retreats/yoga/italy'
  }
];

interface CategoriesProps {
  onLinkClick?: () => void;
}

const RetreatCategories = ({ onLinkClick }: CategoriesProps) => {
  return (
    <VStack
      align="start"
      flexShrink={0}
      width={{ base: '100%', lg: 100, xl: 180 }}
    >
      <Heading as="h2" fontSize={18} fontFamily="ProximaNova">
        Retreat categories
      </Heading>
      {RETREAT_POPULAR_PAGES.map(page => (
        <Link
          as="a"
          key={page.title}
          href={page.url}
          target="_blank"
          fontFamily="ProximaNova"
          fontSize={16}
          onClick={onLinkClick}
          _hover={{ textDecoration: 'underline' }}
        >
          {page.title}
        </Link>
      ))}
    </VStack>
  );
};

export default RetreatCategories;
