import axios from 'axios';
import { firebaseAuth, getAuthHeaders, getIdToken } from 'lib/firebase/auth';
import { createDocRef, getDocument } from 'lib/firebase/utils';
import { User } from 'lib/models/user';
import queryString from 'query-string';
import { EventOccurrence } from 'services/events';
import { Lang } from 'services/interface';
import { ShopProductSummary } from 'services/shops/interface';

interface SendUserEmailVerificationLinkParams {
  action: 'ADD' | 'VERIFY' | 'REMOVE' | 'CHANGE';
  email_verification_type?: 'LINK' | '4_DIGIT_CODE'; // For add action
  email_id?: string;
  verification_code?: string; // for verify action
  old_email?: string; // For change action
  new_email?: string; // For change action
}

const sendUserEmailVerificationLink = async (
  params: SendUserEmailVerificationLinkParams
) => {
  const token = await getIdToken();
  const config = getAuthHeaders(token);

  return axios.post(
    `${process.env.REACT_APP_USER_API_HOST}/api/v1/users/emails`,
    params,
    config
  );
};

type UserEventTypes = 'LIVE_STREAM' | 'WORKSHOP' | 'SCHEDULED_GROUP_MEDITATION';

type UserEventOccurrenceTypes = 'past' | 'live' | 'future';

export interface UserEventsFilter {
  event_types?: UserEventTypes[];
  occurrence_types?: UserEventOccurrenceTypes[];
  offset: number;
  limit: number;
  content_langs?: string[];
  device_lang?: string;
}

interface GetMyEventListParams {
  filter: UserEventsFilter;
}

interface MyEventShopProductMetaData {
  shop_product_id: string;
  shop_product_summary: ShopProductSummary;
}

type GetMyEventListItemMetaData = MyEventShopProductMetaData;

interface ShopProductMyEventSummary {
  id: string;
  description: string;
  lang: Lang;
  number_of_attendees: number;
  owner: User;
  privacy: string;
  shop_product_id: string;
  status: 'PENDING' | 'APPROVED' | 'CANCELLED' | 'DELETED';
  title: string;
  type: string;
  _next_occurrences: EventOccurrence[];
}

interface GetMyEventListItem {
  item_summary: {
    event_summary: ShopProductMyEventSummary;
  };
  metadata: GetMyEventListItemMetaData;
}

type GetMyEventListApiResponse = GetMyEventListItem[];

export const getMyEventList = async ({ filter }: GetMyEventListParams) => {
  const authToken = await getIdToken();
  const config = getAuthHeaders(authToken);
  let url = `${process.env.REACT_APP_USER_API_HOST}/api/v1/users/my-events`;
  const query = queryString.stringify(filter, {
    arrayFormat: 'comma'
  });

  if (query) {
    url += `?${query}`;
  }

  const response = await axios.get<GetMyEventListApiResponse>(url, config);
  const totalEvents = Number(response.headers['x-total-count'] ?? 0);
  const myEvents =
    response.data?.map(details => {
      return {
        eventSummary: details.item_summary.event_summary,
        metaData: details.metadata
      };
    }) ?? [];

  return {
    myEvents,
    totalEvents
  };
};

export interface UserSettingDetails {
  is_subscriber?: boolean;
}

/**
 * Get logged in user private settings details.
 */
export const getUserPrivateSettings = () => {
  const userDetails = firebaseAuth.currentUser;
  const docRef = createDocRef(`users/${userDetails?.uid}/private/settings`);

  return getDocument(docRef).then(doc => {
    if (doc.exists()) {
      const data: UserSettingDetails = doc.data();
      return data;
    }

    return undefined;
  });
};

export default sendUserEmailVerificationLink;
