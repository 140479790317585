import { Box, Stack, VStack } from '@chakra-ui/react';
import useBreakpoint from 'hooks/useBreakpoint';
import React from 'react';
import ReconnectWithYourself from './ReconnectWithYourself';
import RetreatCategories from './RetreatCategories';
import RetreatsTagline from './RetreatsTagline';
import TopRetreats from './TopRetreats';

interface Props {
  onLinkClick?: () => void;
}

const RetreatsSubNav = ({ onLinkClick }: Props) => {
  const { isLgScreen } = useBreakpoint();

  return (
    <VStack
      width="100%"
      alignItems="stretch"
      pb={10}
      mt={{ base: 0, lg: 20, xl: 112 }}
    >
      {isLgScreen && <RetreatsTagline />}

      <Stack
        direction={{ base: 'column', lg: 'row' }}
        spacing={10}
        mt={5}
        alignItems="stretch"
        width="100%"
      >
        <RetreatCategories onLinkClick={onLinkClick} />

        {isLgScreen && <Box width="1px" background="#DFDFDF" />}

        <TopRetreats />

        {isLgScreen && <Box width="1px" background="#DFDFDF" />}

        <ReconnectWithYourself />
      </Stack>
    </VStack>
  );
};

export default RetreatsSubNav;
