import { useEffect } from 'react';
import { useLocation } from 'react-router';

const pageWithDefaultScrollbar = ['/', '/work', '/workshops', '/marketplace'];

const MainPagesScrollController = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    const htmlElement = document.documentElement;
    const isDefaultScroll = pageWithDefaultScrollbar.find(
      path => path === currentPath
    );
    const isContainScrollbarClass =
      htmlElement.classList.contains('default-scrollbar');

    if (isDefaultScroll && !isContainScrollbarClass) {
      htmlElement.classList.add('default-scrollbar');
    }

    if (!isDefaultScroll && isContainScrollbarClass) {
      htmlElement.classList.remove('default-scrollbar');
    }
  }, [currentPath]);

  return null;
};

export default MainPagesScrollController;
