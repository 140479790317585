import queryString from 'query-string';
import useSWR from 'swr';
import { ShopProductFilter } from '../interface';
import { getShopProducts } from '../queries';

const useGetShopProducts = (filter?: ShopProductFilter) => {
  const { data, isValidating } = useSWR(
    () => {
      if (filter) {
        const filterQueryString = queryString.stringify(filter, {
          arrayFormat: 'comma'
        });
        return `/api/shop/products?${filterQueryString}`;
      }

      return null;
    },
    async () => {
      if (!filter) return null;
      const data = await getShopProducts(filter);

      return {
        data: data?.data,
        totalCount: data?.totalCount
      };
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  );

  return {
    shopProducts: data?.data,
    totalCount: data?.totalCount,
    isLoading: isValidating
  };
};

export default useGetShopProducts;
