import { Box, Collapse, useDisclosure, VStack } from '@chakra-ui/react';
import { useAnalytics } from 'hooks/useAnalytics';
import useBreakpoint from 'hooks/useBreakpoint';
import { GA_CLICK_EVENT_NAMES } from 'lib/firebase/ga_events';
import React from 'react';
import SBold from 'views_2/Retreats/components/typography/SBold';
import ShowMore from './ShowMore';

interface Props {
  title: string;
  isShowMore?: boolean;
  startingHeight?: number;
  filterType: string;
  children: React.ReactNode;
}

const LeftFilterBase = ({
  title,
  isShowMore,
  startingHeight,
  children,
  filterType
}: Props) => {
  const { trackGAClickEvent } = useAnalytics();
  const { isOpen, onToggle } = useDisclosure();
  const { isLgScreen } = useBreakpoint();

  const height = startingHeight || (isLgScreen ? 150 : 120);

  const onClickShowMore = () => {
    if (!isOpen) {
      trackGAClickEvent({
        click_event_name: GA_CLICK_EVENT_NAMES.SIDEBAR_FILTER_SHOW_MORE_CLICKED,
        page_name: 'retreats_search_page',
        filter_type: filterType
      });
    }

    onToggle();
  };

  return (
    <VStack width="100%" spacing={5} alignItems="flex-start">
      <SBold>{title}</SBold>
      <Box
        as={isShowMore ? Collapse : Box}
        width="100%"
        {...(isShowMore
          ? { startingHeight: height, in: isOpen, animateOpacity: true }
          : {})}
      >
        {children}
      </Box>
      {isShowMore && <ShowMore isOpen={isOpen} onToggle={onClickShowMore} />}
    </VStack>
  );
};

export default LeftFilterBase;
