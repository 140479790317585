import { I18nProvider } from '@lingui/react';
import MetaRefreshRedirect from 'components/MetaRefreshRedirect';
import { AuthPopupProvider } from 'hooks/useAuthPopup';
import { GetAppPopupProvider } from 'hooks/useGetAppPopup';
import { useLinguiI18n } from 'hooks/useLinguiI18n';
import { NotificationProvider } from 'hooks/useNotification';
import { OnboardingProvider } from 'hooks/useOnboarding';
import { SharePopupProvider } from 'hooks/useSharePopup';
import { isProduction } from 'lib/environment';
import React, { lazy, Suspense, useEffect } from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { BaseLocales } from '../locales/i18nLingui';
import { homePageEnglishVariantLang } from './constants';
import DevelopmentRoute from './DevelopmentRoute';
import HeaderLayout from './HeaderLayout';

const FunnelHome = lazy(() => import('views_2/FunnelHome'));
const MediaEnquiries = lazy(() => import('Views/MediaEnquiries'));
const Local = lazy(() => import('Views/LocalPage'));
const MeditationOrigins = lazy(() => import('Views/Topics/med_origins'));
const MeditationBenefits = lazy(() => import('Views/Topics/med_benefits'));
const MeditationPractices = lazy(() => import('Views/Topics/med_practices'));

const PasswordReset = lazy(() => import('components/PasswordReset'));
const PageNotFound = lazy(() => import('components_2/pageNotFound'));

const ABtest = lazy(() => import('views_2/ABtest'));
const FreeTimer = lazy(() => import('views_2/FreeTimer'));
const SeeAllLocalTeachers = lazy(() => import('views_2/LocalTeachers'));
const SeeAllLocalTeachersDynamic = lazy(
  () => import('views_2/LocalTeachers/LocalTeachersDynamic')
);

const Auth = lazy(() => import('views_2/Auth'));
const ResetPassword = lazy(() => import('views_2/ResetPassword'));
const UserProfile = lazy(() => import('views_2/User/profile'));
const ManageSubscription = lazy(() => import('views_2/ManageSubscription'));
const Invoices = lazy(() => import('views_2/ManageSubscription/Invoices'));
const Player = lazy(() => import('views_2/Player'));
const PlaylistBrowse = lazy(() => import('views_2/Playlists/browse'));
const PlaylistDetails = lazy(() => import('views_2/Playlists/details'));
const Hashtag = lazy(() => import('views_2/Hashtag'));
const PlaylistHashTag = lazy(() => import('views_2/Playlists/hashtag'));
const MyPlaylists = lazy(() => import('views_2/MyPlaylists'));
const LibraryItemDetails = lazy(() => import('views_2/LibraryItem/details'));
const RedirectLibraryItemDetails = lazy(
  () => import('views_2/LibraryItem/details/RedirectLibrayItemDetails')
);
const BrowsePage = lazy(() => import('views_2/LibraryItem/browse'));
const BrowsePageByTopic = lazy(
  () => import('views_2/LibraryItem/browse/topic')
);
const Teachers = lazy(() => import('views_2/Publisher/browse'));
const MeditationTeachersJoin = lazy(() => import('views_2/Teacher/join/v2'));
const Live = lazy(() => import('views_2/Live'));
const MyBookmarks = lazy(() => import('views_2/MyBookmarks'));
const TopicsAll = lazy(() => import('views_2/Topics/seeAll'));
const TopicMusicDetails = lazy(() => import('views_2/Topics/details/music'));
const TopicKidsDetails = lazy(() => import('views_2/Topics/details/kids'));
const MemberPlusV2 = lazy(() => import('views_2/MemberPlus/v2'));
const LocalPublisher = lazy(() => import('views_2/Local'));
const LocalPublisherDynamic = lazy(() => import('views_2/Local/LocalDynamic'));

const LocalDirectory = lazy(() => import('directory/local'));
const CourseDirectory = lazy(() => import('directory/course'));
const TeacherDirectory = lazy(() => import('directory/teachers'));
const PlaylistsDirectory = lazy(() => import('directory/playlist'));
const GmDirectory = lazy(() => import('directory/gms'));
const GmByTopicDirectory = lazy(() => import('directory/gms/details'));
const RetreatsDirectory = lazy(() => import('directory/retreats'));

const HelpNow = lazy(() => import('views_2/HelpNow'));
const Yoga = lazy(() => import('views_2/Yoga'));
const SearchResultsPage = lazy(() => import('views_2/Search'));
const Circle = lazy(() => import('views_2/Circles/Circle'));

const FreeTrial = lazy(() => import('components/FreeTrial'));
const CourseFrontPage = lazy(() => import('Views/Courses'));
const CoursesDetailsPage = lazy(
  () => import('Views/Courses/CoursesDetailsPages')
);

const WorkShopDetailsPage = lazy(
  () => import('views_2/Workshop/WorkshopDetailsPage')
);

const WorkshopCheckoutFlow = lazy(
  () => import('views_2/Workshop/WorkshopCheckoutFlow')
);

const ShopProductRedirectionPage = lazy(
  () => import('views_2/Workshop/ShopProductRedirectionPage')
);

const PurchaseConfirmationPage = lazy(
  () =>
    import('views_2/Workshop/PurchaseConfirmationPage/PurchaseConfirmationPage')
);

const ShopProductCheckoutMiddlewarePage = lazy(
  () =>
    import(
      'views_2/Workshop/PurchaseConfirmationPage/ShopProductCheckoutMiddlewarePage'
    )
);

const B2BWorkshopFrontPage = lazy(
  () => import('views_2/Workshop/B2BWorkshop/B2BWorkshopFrontPage')
);

const MemberPlusThirtyDaysTrialPage = lazy(
  () => import('views_2/MemberPlusTrial/MemberPlusThirtyDaysTrialPage')
);

const MemberPlusSevenDaysTrialPage = lazy(
  () => import('views_2/MemberPlusTrial/MemberPlusSevenDaysTrialPage')
);

// Use by App
const MemberPlusAppSubscriptionPage = lazy(
  () => import('views_2/AppSubscription/MemberPlusAppSubscriptionPage')
);

const MeditationGroupsPage = lazy(() => import('views_2/MeditationGroupsPage'));

const HashtagsPage = lazy(() => import('views_2/Hashtags'));
const TeacherProfileController = lazy(
  () => import('views_2/TeacherProfile/TeacherProfileController')
);

const RedirectPage = lazy(() => import('views_2/Redirect'));

const WalmartCodeRedeemFlow = lazy(
  () => import('views_2/WalmartCodeRedeemFlow')
);

const WalmartLandingPage = lazy(
  () => import('views_2/WalmartCodeRedeemFlow/WalmartLandingPage')
);

const WalmartLandingVariantPage = lazy(
  () =>
    import(
      'views_2/WalmartCodeRedeemFlow/WalmartLandingPage/WalmartLandingVariantPage'
    )
);

const RedeemPage = lazy(() => import('views_2/Redeem'));

const MemberPlusLandingPage = lazy(
  () => import('views_2/MemberPlusLandingPage')
);

const EditorialPage = lazy(() => import('views_2/Editorial'));

const EditorialFeaturedTest = lazy(
  () => import('views_2/Editorial/EditorialFeaturedTest')
);
const EditorialDetailPage = lazy(
  () => import('views_2/Editorial/EditorialDetailPage')
);

const EditorialSEOPage = lazy(() => import('views_2/EditorialSEOPage'));

const VerifySessionPage = lazy(() => import('views_2/Auth/VerifySession'));

const Legal = lazy(() => import('views_2/Legal'));

const BranchDownloadAppPage = lazy(
  () => import('views_2/BranchDownloadAppPage')
);

const MeditationTopicsPage = lazy(() => import('views_2/MeditationTopics'));

const GlobalComponents = lazy(
  () => import('global_components/GlobalComponents')
);

const LgTvSubscriptionPage = lazy(
  () => import('views_2/LgTvSubscription/LgTvSubscriptionPage')
);

const PodcastTracksPage = lazy(
  () => import('views_2/TagBasedTracks/PodcastTracksPage')
);
const PublishingPage = lazy(() => import('views_2/PublishingPage'));

const QuoteDownloadAppPage = lazy(() => import('views_2/QuoteDownloadAppPage'));

const CMSPreview = lazy(() => import('views_2/CMSPreview'));

const RetreatsPages = lazy(() => import('Routes/RetreatsPages'));

const WellnessPage = lazy(() => import('views_2/Wellness'));

const Homepage = lazy(() => import('pages/Homepage'));

const AboutUs = lazy(() => import('pages/AboutUs'));

const ReviewPage = lazy(() => import('views_2/Review'));

const CareersPage = lazy(() => import('views_2/Careers'));

const CareerDetailsPage = lazy(() => import('views_2/Careers/DetailPage'));

const Pages = () => {
  // eslint-disable-next-line no-shadow
  const i18n = useLinguiI18n();
  i18n.activate(BaseLocales.English);

  useEffect(() => {
    document.documentElement.lang = BaseLocales.English;
  }, []);

  return (
    <I18nProvider i18n={i18n} language={BaseLocales.English}>
      <AuthPopupProvider>
        <NotificationProvider>
          <SharePopupProvider>
            <GetAppPopupProvider>
              <Suspense fallback={<></>}>
                <Switch>
                  <Route
                    path="/circles/:circleId"
                    render={props => {
                      return (
                        <Redirect
                          to={`/groups/${props.match.params.circleId}`}
                        />
                      );
                    }}
                  />
                  <Route path="/groups/:circleId" component={Circle} />

                  {/* global-components */}
                  <Route
                    path="/global-components"
                    component={DevelopmentRoute(GlobalComponents)}
                  />

                  {/* player */}
                  <Route path="/play" component={Player} />

                  {/* verify session and login using it */}
                  <Route path="/verify-session" component={VerifySessionPage} />

                  {/* free trial */}
                  <Route
                    exact
                    path="/subscribe/free-trial"
                    component={FreeTrial}
                  />

                  <Route
                    path="/subscribe/lgtv"
                    component={LgTvSubscriptionPage}
                  />

                  <Route
                    path="/subscribe/walmartplus"
                    component={DevelopmentRoute(WalmartCodeRedeemFlow)}
                  />

                  <Route path="/walmart" component={WalmartLandingPage} />

                  <Route path="/trial" component={WalmartLandingVariantPage} />

                  <Route
                    path="/app/memberplus"
                    component={MemberPlusLandingPage}
                  />

                  <Route
                    exact
                    path="/spotify"
                    component={WalmartLandingVariantPage}
                  />

                  <Route
                    exact
                    path="/subscribe/30-day-trial"
                    component={MemberPlusThirtyDaysTrialPage}
                  />

                  <Route
                    exact
                    path="/subscribe/7-day-trial"
                    component={MemberPlusSevenDaysTrialPage}
                  />

                  <Route
                    exact
                    path="/subscribe"
                    component={MemberPlusAppSubscriptionPage}
                  />

                  <Route exact path="/legal/:slug" component={Legal} />

                  <Route exact path="/redeem" component={RedeemPage} />

                  <Route
                    exact
                    path="/download-app"
                    component={DevelopmentRoute(BranchDownloadAppPage)}
                  />

                  <Route>
                    <OnboardingProvider>
                      <HeaderLayout>
                        <Route
                          exact
                          path={`/:lang(${homePageEnglishVariantLang})`}
                          component={Homepage}
                        />

                        <Route
                          exact
                          path={[
                            '/individuals',
                            `/:lang(${homePageEnglishVariantLang})/individuals`
                          ]}
                          component={() => <Redirect to="/" />}
                        />

                        <Route
                          exact
                          path="/wellness/:id"
                          component={WellnessPage}
                        />

                        <Route exact path="/reviews" component={ReviewPage} />

                        <Route exact path="/careers" component={CareersPage} />

                        <Route
                          exact
                          path="/careers/:id/:tab(application)?"
                          component={CareerDetailsPage}
                        />

                        <Route
                          exact
                          path="/ab-test"
                          component={DevelopmentRoute(ABtest)}
                        />
                        <Route
                          exact
                          path="/my-playlists"
                          component={DevelopmentRoute(MyPlaylists)}
                        />
                        <Route
                          exact
                          path="/my-profile"
                          component={DevelopmentRoute(UserProfile)}
                        />
                        <Route
                          exact
                          path="/my-bookmarks"
                          component={DevelopmentRoute(MyBookmarks)}
                        />

                        <Route
                          exact
                          path="/preview/:slug"
                          component={DevelopmentRoute(CMSPreview)}
                        />

                        {/* live */}
                        <Route exact path="/live" component={Live} />

                        <Route
                          exact
                          path="/live/:eventId"
                          render={props => {
                            window.location.replace(
                              `${process.env.REACT_APP_WEBAPP_HOST}${props.location.pathname}`
                            );
                            return null;
                          }}
                        />

                        <Route
                          exact
                          path="/users/:userId"
                          render={props => {
                            window.location.replace(
                              `${process.env.REACT_APP_WEBAPP_HOST}${props.location.pathname}`
                            );
                            return null;
                          }}
                        />

                        <Route exact path="/yoga" component={Yoga} />
                        <Route
                          exact
                          path="/search"
                          component={SearchResultsPage}
                        />
                        <Route
                          exact
                          path="/yoga/:slug"
                          render={props => (
                            <EditorialSEOPage
                              slug={`yoga--${props.match.params.slug}`}
                            />
                          )}
                        />

                        <Route
                          exact
                          path="/editorial"
                          component={
                            !isProduction ? EditorialPage : PageNotFound
                          }
                        />

                        <Route
                          exact
                          path="/editorial/featured-test"
                          component={
                            !isProduction ? EditorialFeaturedTest : PageNotFound
                          }
                        />

                        <Route
                          exact
                          path="/editorial/:slug"
                          component={
                            !isProduction ? EditorialDetailPage : PageNotFound
                          }
                        />

                        <Route
                          path="/:publisherSlug/workshops/:productId"
                          component={WorkShopDetailsPage}
                          exact
                        />

                        <Route
                          exact
                          path="/:publisherSlug/workshops/:productId/confirmation"
                          component={PurchaseConfirmationPage}
                        />

                        <Route
                          exact
                          path="/marketplace"
                          component={B2BWorkshopFrontPage}
                        />

                        <Route
                          path="/shop_products/:productId"
                          component={ShopProductRedirectionPage}
                          exact
                        />

                        <Route
                          path="/shop_products/:productId/cancel"
                          component={ShopProductRedirectionPage}
                          exact
                        />

                        <Route
                          path="/shop_products/:productId/success"
                          component={ShopProductCheckoutMiddlewarePage}
                          exact
                        />

                        <Route
                          path="/:publisherSlug/workshops/:productId/:tab"
                          component={WorkshopCheckoutFlow}
                        />

                        <Route exact path="/" component={Homepage} />

                        <Route
                          exact
                          path={[
                            '/:lang(br)/meditação-guiada/:page([1-9]d*)?',
                            '/:lang(br)/meditação-guiada/:filter(popular)'
                          ]}
                          component={BrowsePage}
                        />

                        <Route
                          exact
                          path="/dir/local"
                          component={LocalDirectory}
                        />

                        <Route
                          sensitive
                          exact
                          path="/local/other/:decodeRegionData"
                          component={LocalPublisherDynamic}
                        />

                        <Route
                          sensitive
                          exact
                          path="/local/other/:decodeRegionData/meditation-teachers/:page([1-9]\d*)?"
                          component={SeeAllLocalTeachersDynamic}
                        />

                        <Route
                          sensitive
                          exact
                          path="/local/:country/:city"
                          component={LocalPublisher}
                        />

                        <Route
                          sensitive
                          exact
                          path="/local/:country/:city/meditation-teachers/:page([1-9]\d*)?"
                          component={SeeAllLocalTeachers}
                        />
                        <Route exact path="/local" component={Local} />

                        <Route
                          exact
                          path="/meditation-playlists"
                          component={PlaylistBrowse}
                        />

                        <Route exact path="/hashtag" component={Hashtag} />

                        <Route
                          exact
                          path="/hashtag/:hashtag"
                          component={PlaylistHashTag}
                        />

                        <Route
                          exact
                          path="/meditation-playlists/:id"
                          component={PlaylistDetails}
                        />
                        <Route
                          exact
                          path="/playlists/:id"
                          component={PlaylistDetails}
                        />

                        <Route
                          exact
                          path="/publish"
                          component={PublishingPage}
                        />
                        {/* Course  directory */}
                        <Route
                          exact
                          path="/dir/meditation-courses"
                          component={CourseDirectory}
                        />

                        {/* Teacher  directory */}
                        <Route
                          exact
                          path="/dir/meditation-teachers"
                          component={TeacherDirectory}
                        />
                        <Route
                          sensitive
                          path="/dir/meditation-teachers/:startwith(hash|more|[a-z]{1})"
                          component={TeacherDirectory}
                        />

                        {/* Playlists  directory */}
                        <Route
                          exact
                          path="/dir/playlists"
                          component={PlaylistsDirectory}
                        />
                        <Route
                          sensitive
                          path="/dir/playlists/:startwith(hash|more|[a-z]{1})"
                          component={PlaylistsDirectory}
                        />

                        {/* Topic  directory */}
                        <Route
                          exact
                          path="/dir/guided-meditations"
                          component={GmDirectory}
                        />
                        <Route
                          exact
                          path="/dir/guided-meditations/:meditationTopic"
                          component={GmByTopicDirectory}
                        />
                        <Route
                          sensitive
                          exact
                          path="/dir/guided-meditations/:meditationTopic/:startwith(hash|more|[a-z]{1})"
                          component={GmByTopicDirectory}
                        />

                        <Route
                          exact
                          path="/dir/retreats/:location?"
                          component={RetreatsDirectory}
                        />

                        {/* Login */}
                        <Route
                          exact
                          path="/login"
                          render={props => <Auth authType="login" {...props} />}
                        />
                        <Route
                          exact
                          path="/signup"
                          render={props => (
                            <Auth authType="signup" {...props} />
                          )}
                        />
                        <Route
                          exact
                          path="/logout"
                          render={props => (
                            <Auth authType="logout" {...props} />
                          )}
                        />

                        <Route
                          exact
                          path="/reset-password"
                          component={ResetPassword}
                        />

                        <Route
                          exact
                          path="/password-reset"
                          component={PasswordReset}
                        />

                        {/* User */}

                        {/* Manage subscription */}
                        <Route
                          exact
                          path="/manage-subscription"
                          component={ManageSubscription}
                        />
                        <Route
                          exact
                          path="/manage-subscription/invoices"
                          component={Invoices}
                        />

                        {/* static */}
                        <Route
                          exact
                          path="/meditation-app"
                          component={FunnelHome}
                        />
                        <Route exact path="/about" component={AboutUs} />

                        <Route
                          exact
                          path="/member-plus"
                          component={MemberPlusV2}
                        />
                        <Route
                          exact
                          path="/premium"
                          component={() => <Redirect to="/member-plus" />}
                        />

                        <Route
                          exact
                          path="/meditation-timer"
                          component={FreeTimer}
                        />
                        <Route
                          exact
                          path="/media-enquiries"
                          component={MediaEnquiries}
                        />
                        {/* <Route exact={true} path="/sitemap" component={Sitemap} /> */}

                        <Route
                          exact
                          path="/meditation-groups"
                          component={MeditationGroupsPage}
                        />
                        <Route
                          exact
                          path="/meditation-groups/:slug"
                          component={() => <Circle />}
                        />
                        <Route
                          exact
                          path="/meditation-groups/:slug/join"
                          component={() => <Circle />}
                        />
                        <Route
                          path="/guided-meditations/browse/:topic/:page([1-9]\d*)?"
                          component={() => <BrowsePageByTopic type="guided" />}
                        />
                        <Route
                          exact
                          path="/guided-meditations/:itemId"
                          component={RedirectLibraryItemDetails}
                        />

                        <Route
                          exact
                          path="/guided-meditations/:itemId/:lang"
                          component={RedirectLibraryItemDetails}
                        />

                        {/* guided meditations */}
                        <Route
                          exact
                          path={[
                            '/guided-meditations/:page([1-9]d*)?',
                            '/guided-meditations/:filter(popular)'
                          ]}
                          component={BrowsePage}
                        />

                        <Route
                          exact
                          path="/podcasts"
                          component={PodcastTracksPage}
                        />

                        <Route
                          exact
                          path="/:teacher/guided-meditations/:slug"
                          component={LibraryItemDetails}
                        />

                        {/* <Route exact={true} path="/guided-meditations/:filter(new|popular|staff-picks|browse)?" component={BrowsePage} /> */}

                        {/* teachers */}
                        <Route
                          exact
                          path="/meditation-teachers"
                          component={Teachers}
                        />

                        <Route
                          path="/meditation-teachers/join"
                          component={MeditationTeachersJoin}
                        />

                        <Route
                          exact
                          path="/meditation-teachers/:slug(popular|new|starts-with-[aA-zZ]{1})/:page([1-9]\d*)?"
                          component={Teachers}
                        />

                        {/* courses */}
                        <Route
                          exact
                          path="/meditation-courses"
                          component={CourseFrontPage}
                        />

                        <Route
                          exact
                          path="/meditation-courses/:slug"
                          component={CoursesDetailsPage}
                        />

                        <Route
                          exact
                          path="/meditation-topics/coronavirus"
                          component={() => (
                            <MetaRefreshRedirect to="/meditation-topics" />
                          )}
                        />

                        <Route
                          exact
                          path="/meditation-topics/music"
                          component={() => <Redirect to="/meditation-music" />}
                        />

                        <Route
                          exact
                          path="/meditation-topics/:topic/browse/all"
                          component={({
                            match
                          }: RouteComponentProps<{ topic: string }>) => (
                            <Redirect
                              to={`/meditation-topics/${match.params.topic}`}
                            />
                          )}
                        />
                        <Route
                          exact
                          path="/meditation-topics/:topic/browse/:type(talks|music|guided)/:page([1-9]\d*)?"
                          component={({
                            match
                          }: RouteComponentProps<{
                            topic: string;
                            type: string;
                            page?: string;
                          }>) => {
                            const pagePath = match.params.page
                              ? `/${match.params.page}`
                              : '';
                            if (match.params.type === 'music') {
                              return (
                                <Redirect
                                  to={`/meditation-music/browse/${match.params.topic}${pagePath}`}
                                />
                              );
                            }

                            if (match.params.type === 'guided') {
                              return (
                                <Redirect
                                  to={`/guided-meditations/browse/${match.params.topic}${pagePath}`}
                                />
                              );
                            }

                            return <BrowsePageByTopic type="talks" />;
                          }}
                        />
                        <Route
                          path="/meditation-topics/:topic/browse/:type/:page([^-]*)"
                          component={({
                            match
                          }: RouteComponentProps<{ topic: string }>) => (
                            <Redirect
                              to={`/meditation-topics/${match.params.topic}/browse/guided`}
                            />
                          )}
                        />
                        <Route
                          exact
                          path="/meditation-topics/:topic/browse"
                          component={({
                            match
                          }: RouteComponentProps<{ topic: string }>) => (
                            <Redirect
                              to={`/meditation-topics/${match.params.topic}`}
                            />
                          )}
                        />

                        <Route
                          exact
                          path="/meditation-practices"
                          component={MeditationPractices}
                        />
                        <Route
                          exact
                          path="/meditation-origins"
                          component={MeditationOrigins}
                        />
                        <Route
                          exact
                          path="/meditation-benefits"
                          component={MeditationBenefits}
                        />

                        <Route
                          path="/meditation-origins/:slug"
                          component={({
                            match
                          }: RouteComponentProps<{ slug: string }>) => (
                            <Redirect
                              to={`/meditation-topics/${match.params.slug}`}
                            />
                          )}
                        />
                        <Route
                          path="/meditation-benefits/:slug"
                          component={({
                            match
                          }: RouteComponentProps<{ slug: string }>) => (
                            <Redirect
                              to={`/meditation-topics/${match.params.slug}`}
                            />
                          )}
                        />
                        <Route
                          path="/meditation-practices/:slug"
                          component={({
                            match
                          }: RouteComponentProps<{ slug: string }>) => (
                            <Redirect
                              to={`/meditation-topics/${match.params.slug}`}
                            />
                          )}
                        />

                        <Route
                          exact
                          path="/meditation-topics"
                          component={TopicsAll}
                        />
                        <Route
                          exact
                          path="/meditation-music"
                          component={TopicMusicDetails}
                        />
                        <Route
                          path="/meditation-music/browse/:topic/:page([1-9]\d*)?"
                          component={() => <BrowsePageByTopic type="music" />}
                        />
                        <Route
                          path="/meditation-music/:slug"
                          component={({
                            match
                          }: RouteComponentProps<{ slug: string }>) => (
                            <Redirect
                              to={`/meditation-topics/${match.params.slug}`}
                            />
                          )}
                        />
                        <Route
                          exact
                          path="/meditation-topics/children"
                          render={props => {
                            // eslint-disable-next-line no-param-reassign
                            props.match.params.slug = 'children';
                            return <TopicKidsDetails {...props} />;
                          }}
                        />

                        <Route
                          path={[
                            '/meditation-topics/:slug/tabs',
                            '/topics/:slug/tabs',
                            '/topics/:slug'
                          ]}
                          component={({
                            match
                          }: RouteComponentProps<{ slug: string }>) => (
                            <Redirect
                              to={`/meditation-topics/${match.params.slug}`}
                            />
                          )}
                        />
                        <Route
                          exact
                          path={['/meditation-topics/:slug']}
                          render={props => {
                            return (
                              <MeditationTopicsPage
                                slug={props.match.params.slug}
                              />
                            );
                          }}
                        />
                        {/* topics */}
                        <Route
                          exact
                          path="/meditation-topics/:topic/:subtopic"
                          render={props => {
                            const { topic, subtopic } = props.match.params;

                            return (
                              <EditorialSEOPage
                                slug={`meditation-topics-${topic}--${subtopic}`}
                              />
                            );
                          }}
                        />

                        <Route exact path="/explore/:slug">
                          <HashtagsPage type="HASHTAG" />
                        </Route>
                        {/* local */}
                        <Route
                          exact
                          path="/:citySlug(newyork-us|losangeles-us|london-gb|sanfrancisco-us|chicago-us|washington-us|sydney-au|melbourne-au|seattle-us|toronto-ca)/meditation"
                          render={props => {
                            const locArray =
                              props.match.params.citySlug.split('-');
                            const country = locArray[1];
                            let newCity = locArray[0];

                            switch (newCity) {
                              case 'newyork':
                                newCity = 'new-york';
                                break;
                              case 'losangeles':
                                newCity = 'los-angeles';
                                break;
                              case 'sanfrancisco':
                                newCity = 'san-francisco';
                                break;
                              default:
                                break;
                            }

                            return (
                              <Redirect to={`/local/${country}/${newCity}`} />
                            );
                          }}
                        />

                        <Route
                          exact
                          path="/quote"
                          component={QuoteDownloadAppPage}
                        />

                        <Route exact path="/help-now" component={HelpNow} />

                        <Route
                          exact
                          path="/redirect"
                          component={RedirectPage}
                        />

                        <Route
                          path={['/retreats', '/retreat']}
                          component={RetreatsPages}
                        />

                        {/* TeacherProfile */}
                        <Route
                          exact
                          path="/:teacherSlug/home"
                          render={props => {
                            return (
                              <MetaRefreshRedirect
                                to={`/${props.match.params.teacherSlug}`}
                              />
                            );
                          }}
                        />
                        <Route
                          exact
                          path="/:teacherSlug/:tab?"
                          component={TeacherProfileController}
                        />

                        <Route path="*" component={PageNotFound} />
                      </HeaderLayout>
                    </OnboardingProvider>
                  </Route>
                </Switch>
              </Suspense>
            </GetAppPopupProvider>
          </SharePopupProvider>
        </NotificationProvider>
      </AuthPopupProvider>
    </I18nProvider>
  );
};

export default Pages;
