import { ComponentStyleConfig } from '@chakra-ui/react';

const Modal: ComponentStyleConfig = {
  parts: ['dialogContainer', 'dialog'],
  sizes: {
    full: {
      dialogContainer: {
        height: '100dvh !important' // to solve safari mobile issue not showing full modal
      },
      dialog: {
        borderRadius: 'none'
      }
    }
  }
};

export default Modal;
