import { VStack } from '@chakra-ui/react';
import { useAnalytics } from 'hooks/useAnalytics';
import { GA_CLICK_EVENT_NAMES } from 'lib/firebase/ga_events';
import React from 'react';
import { getAmenityText } from 'services/shops/helper';
import { Amenity } from 'services/shops/interface';
import { useRetreatDiscovery } from 'views_2/RetreatsDiscovery/hooks/useRetreatDiscovery';
import CheckboxFilter from '../CheckboxFilter';
import LeftFilterBase from '../LeftFilterBase';

export const AMENETIES_FILTER = [
  {
    value: Amenity.AC_IN_ROOMS,
    title: 'A/C in Rooms'
  },
  {
    value: Amenity.FREE_WIFI,
    title: 'Free Wifi'
  },
  {
    value: Amenity.FITNESS_CENTER,
    title: 'Fitness Center'
  },
  {
    value: Amenity.YOGA_STUDIO,
    title: 'Yoga Studio'
  },
  {
    value: Amenity.POOL,
    title: 'Pool'
  },
  {
    value: Amenity.GARDEN,
    title: 'Garden'
  },
  {
    value: Amenity.SPA,
    title: 'Spa'
  },
  {
    value: Amenity.SAUNA,
    title: 'Sauna'
  },
  {
    value: Amenity.HOT_TUB,
    title: 'Hot Tub'
  },
  {
    value: Amenity.BICYCLES_FOR_RENT,
    title: 'Bicycles For Rent'
  },
  {
    value: Amenity.LAUNDRY,
    title: 'Laundry'
  },
  {
    value: Amenity.TOWELS,
    title: 'Towels'
  },
  {
    value: Amenity.HOUSEKEEPING,
    title: 'Housekeeping'
  },
  {
    value: Amenity.COFFEE_TEA,
    title: 'Coffee / Tea'
  },
  {
    value: Amenity.CAFE,
    title: 'Cafe'
  },
  {
    value: Amenity.RESTAURANT,
    title: 'Restaurant'
  },
  {
    value: Amenity.KITCHEN,
    title: 'Kitchen'
  },
  {
    value: Amenity.WHEELCHAIR_ACCESSIBLE,
    title: 'Wheelchair Accessible'
  },
  {
    value: Amenity.TOUR_ASSISTANCE,
    title: 'Tour Assistance'
  },
  {
    value: Amenity.FREE_PARKING,
    title: 'Free Parking'
  },
  {
    value: Amenity.HEATING,
    title: 'Heating'
  },
  {
    value: Amenity.SHOWER,
    title: 'Shower'
  },
  {
    value: Amenity.PARKING_WITH_FEES,
    title: 'Parking (with fees)'
  },
  {
    value: Amenity.GYM,
    title: 'Gym'
  },
  {
    value: Amenity.TV,
    title: 'TV'
  },
  {
    value: Amenity.HAIRDRYER,
    title: 'Hairdryer'
  },
  {
    value: Amenity.IRON,
    title: 'Iron'
  },
  {
    value: Amenity.FIRE_PIT,
    title: 'Fire Pit'
  },
  {
    value: Amenity.BBQ_GRILL,
    title: 'BBQ Grill'
  },
  {
    value: Amenity.BATHTUB,
    title: 'Bathtub'
  }
];

const AmenitiesFilter = () => {
  const { trackGAClickEvent } = useAnalytics();
  const {
    filter,
    shopProductStats,
    onChangeCheckboxFilter
  } = useRetreatDiscovery();

  const amenities = AMENETIES_FILTER.map(amenity => {
    const noOfResults = shopProductStats?.amenities?.find(
      stats => stats.tag === `retreat_amenities_${amenity.value}`
    )?.count;

    return {
      ...amenity,
      noOfResults: noOfResults ?? 0
    };
  })
    .filter(amenity => amenity.noOfResults > 0)
    .sort((a, b) => b.noOfResults - a.noOfResults);

  const onOptionClick = (value: Amenity, checked: boolean) => {
    const beforeClickState = filter?.amenities?.includes(value) ?? false;
    trackGAClickEvent({
      click_event_name: GA_CLICK_EVENT_NAMES.SIDEBAR_FILTER_OPTION_CLICKED,
      page_name: 'retreats_search_page',
      filter_type: 'amenities',
      before_click_state: beforeClickState,
      option_name: getAmenityText(value)
    });

    onChangeCheckboxFilter('amenities', checked, value);
  };

  if (amenities.length === 0) return null;

  return (
    <LeftFilterBase
      title="Amenities"
      isShowMore={amenities.length > 5}
      filterType="amenities"
    >
      <VStack
        width="100%"
        alignItems="flex-start"
        spacing={{ base: '24px', lg: 3 }}
      >
        {amenities.map(amenities => (
          <CheckboxFilter
            key={amenities.value}
            title={amenities.title}
            isChecked={filter?.amenities?.includes(amenities.value)}
            noOfResults={amenities.noOfResults}
            onChange={event =>
              onOptionClick(amenities.value, event.target.checked)
            }
          />
        ))}
      </VStack>
    </LeftFilterBase>
  );
};

export default AmenitiesFilter;
