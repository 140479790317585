import { useAnalytics } from 'hooks/useAnalytics';
import usePrevious from 'hooks/usePrevious';
import GA_EVENTS from 'lib/firebase/ga_events';
import noop from 'lodash/noop';
import without from 'lodash/without';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useHistory, useLocation } from 'react-router';
import {
  getRetreatCategoryAndLocationSlug,
  getRetreatCategorySlug
} from 'services/shops/helper';
import useShopProductStats from 'services/shops/hooks/useShopProductStats';
import { ShopProductFilter } from 'services/shops/interface';
import { ShopProductStatsResponse } from 'services/shops/queries';

type CheckboxFilterType =
  | 'categories'
  | 'location_names'
  | 'content_langs'
  | 'atmospheres'
  | 'amenities'
  | 'diets'
  | 'owner_ids'
  | 'tags';

interface Context {
  isLoading: boolean;
  isLoadingStats?: boolean;
  totalCount?: number;
  filter?: ShopProductFilter;
  shopProductStats?: ShopProductStatsResponse | null;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setFilter: React.Dispatch<React.SetStateAction<ShopProductFilter>>;
  onChangeCheckboxFilter: (
    filterType: CheckboxFilterType,
    isChecked: boolean,
    value: string
  ) => void;
  setTotalCount: React.Dispatch<React.SetStateAction<number>>;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  changeRoute: (filter: ShopProductFilter) => void;
}

const RetreatsDiscoveryContext = createContext<Context>({
  isLoading: false,
  isLoadingStats: false,
  totalCount: 0,
  filter: {},
  setIsLoading: noop,
  setFilter: noop,
  onChangeCheckboxFilter: noop,
  setTotalCount: noop,
  currentPage: 1,
  setCurrentPage: noop,
  changeRoute: noop
});

const defaultFilter: ShopProductFilter = {
  sort_option: 'recommended'
};

export const RetreatsDiscoveryContextProvider: React.FC = ({ children }) => {
  const { trackGAEvent } = useAnalytics();
  const history = useHistory();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filter, setFilter] = useState<ShopProductFilter>(defaultFilter);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { shopProductStats: _shopProductStats, isLoading: isLoadingStats } =
    useShopProductStats({
      product_types: ['RETREAT'],
      sort_direction: 'desc',
      ...filter
    });

  const prevShopProductStats = usePrevious(_shopProductStats, true);

  const shopProductStats = _shopProductStats || prevShopProductStats;

  useEffect(() => {
    /* 
    TODO: when it's possible to have filter on page load (e.g filter in URL), 
    include the filter to the tracker and keep send it only on first page load
    */

    trackGAEvent({
      event_name: GA_EVENTS.PAGE_VIEW,
      page_name: 'retreats_search_page'
    });
  }, [trackGAEvent]);

  useEffect(() => {
    if (location.state?.filter) {
      setFilter(location.state?.filter);
    } else {
      history.replace(location.pathname + location.search, {
        filter: defaultFilter
      });
    }
  }, [location, history]);

  const changeRoute = useCallback(
    (filter: ShopProductFilter) => {
      const currentLocation = window.location.pathname;
      let targetLocation = `/retreats`;
      if (filter?.categories?.length === 1) {
        targetLocation = getRetreatCategorySlug(filter.categories[0]);
        if (filter?.location_names?.length === 1) {
          targetLocation = getRetreatCategoryAndLocationSlug(
            filter.categories[0],
            filter.location_names[0]
          );
        }
      }
      if (currentLocation !== targetLocation) {
        const currentScroll = window.scrollY;
        history.push(targetLocation, {
          filter
        });
        window.scrollTo(0, currentScroll);
      } else {
        history.replace(targetLocation, {
          filter
        });
      }
    },
    [history]
  );

  const onChangeCheckboxFilter = useCallback(
    (filterType: CheckboxFilterType, isChecked: boolean, value: string) => {
      setFilter(filter => {
        const newFilter = {
          ...filter,
          [filterType]: isChecked
            ? [...(filter[filterType] || []), value]
            : without(filter[filterType] || [], value)
        };
        changeRoute(newFilter);
        return newFilter;
      });
    },
    [changeRoute]
  );

  return (
    <RetreatsDiscoveryContext.Provider
      value={{
        isLoading,
        isLoadingStats,
        filter,
        shopProductStats,
        totalCount,
        setIsLoading,
        setFilter,
        onChangeCheckboxFilter,
        setTotalCount,
        currentPage,
        setCurrentPage,
        changeRoute
      }}
    >
      {children}
    </RetreatsDiscoveryContext.Provider>
  );
};

export const useRetreatDiscovery = () => {
  return useContext(RetreatsDiscoveryContext);
};
