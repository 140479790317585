import { Text, TextProps } from '@chakra-ui/react';
import React from 'react';

export default function SBold({ children, ...props }: TextProps) {
  return (
    <Text
      fontSize="18px"
      lineHeight="23px"
      color="neutral.900"
      fontWeight={700}
      {...props}
    >
      {children}
    </Text>
  );
}
