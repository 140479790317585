import numeral from 'numeral';
import { Price } from 'services/shops/interface';

export const getPriceWithCurrency = (
  price?: Price,
  showCurrencyLabel = true
) => {
  if (!price) return '0';

  switch (price.currency) {
    case 'USD': {
      const formattedPrice = numeral(price.price).format('$0,0[.]00');
      return showCurrencyLabel ? `${formattedPrice} USD` : formattedPrice;
    }

    default:
      return `${price.price}`;
  }
};

export const createPlaceName = (
  city?: string,
  state?: string,
  country?: string
) => {
  let place = country ?? '';
  if (country?.toLowerCase() === 'united states' && state) {
    place = `${state}, ${place}`;
  }
  if (city) {
    place = `${city}, ${place}`;
  }

  return place;
};

export default getPriceWithCurrency;
