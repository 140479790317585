import { Text } from '@chakra-ui/react';
import React from 'react';

const RetreatsTagline = () => {
  return (
    <Text fontSize={23} fontFamily="Gelica" py={{ md: 4, xl: 6 }}>
      Real people. Real life. Real time.
    </Text>
  );
};

export default RetreatsTagline;
