import dayjs from 'dayjs';
import { LANGUAGES_LIST } from 'locales/helpers';
import first from 'lodash/first';
import flatten from 'lodash/flatten';
import minBy from 'lodash/minBy';
import startCase from 'lodash/startCase';
import { DateAndTime } from 'services/interface';
import getPriceWithCurrency from 'services/retreats/helpers';
import { VenueLocationResponse } from 'services/retreatVenue/queries';
import {
  AccommodationOption,
  ShopProductSummary
} from 'services/shops/interface';
import { AMENETIES_FILTER } from 'views_2/RetreatsDiscovery/components/filters/AmenitiesFilter';
import { ATMOSPHERE_FILTERS } from 'views_2/RetreatsDiscovery/components/filters/AtmosphereFilter';
import { DIETS_FILTERS } from 'views_2/RetreatsDiscovery/components/filters/DietsFilter';
import { AccommodationOptionType, Price, PriceType } from './interface';

export const getCheapestPriceFromShopProduct = (
  shopProduct: ShopProductSummary
): Price => {
  const noAccommodationPrice = getNoAccommodationPrice(shopProduct);

  const lowestPrice = minBy(
    [
      ...flatten(
        shopProduct?.accommodation?.options.map(option => option.prices)
      ),
      ...(noAccommodationPrice ? [noAccommodationPrice] : [])
    ],
    'price'
  );

  return lowestPrice || { currency: 'USD', price: 0 };
};

export const retreatDurationText = (
  startDate?: DateAndTime,
  endDate?: DateAndTime
) => {
  if (!startDate || !endDate) return '';

  const start = dayjs(startDate.local_time ?? startDate.epoch);
  const end = dayjs(endDate.local_time ?? endDate.epoch);

  const isSameMonth = start.isSame(end, 'month');
  const isSameYear = start.isSame(end, 'year');

  if (isSameMonth)
    return `${start.format('MMMM D')} - ${end.format('D, YYYY')}`;

  if (isSameYear)
    return `${start.format('MMMM D')} - ${end.format('MMMM D, YYYY')}`;

  return `${start.format('MMMM D, YYYY')} - ${end.format('MMMM D, YYYY')}`;
};

export const getDepositPayment = (
  price?: Price,
  noOfGuest = 1,
  accommodationType?: AccommodationOptionType
): Price => {
  if (!price)
    return {
      price: 0,
      currency: 'USD'
    };

  const noOfItem = accommodationType === 'PRIVATE_ROOM' ? 1 : noOfGuest;
  return { price: price.price * 0.1 * noOfItem, currency: price.currency };
};

export const getBalancePayment = (
  price?: Price,
  noOfGuest = 1,
  accommodationType?: AccommodationOptionType
): Price => {
  if (!price)
    return {
      price: 0,
      currency: 'USD'
    };

  const noOfItem = accommodationType === 'PRIVATE_ROOM' ? 1 : noOfGuest;

  return {
    price:
      price.price * noOfItem -
      getDepositPayment(price, noOfGuest, accommodationType).price,
    currency: price.currency
  };
};

export const getRetreatTypeValue = (retreatType?: string) => {
  if (!retreatType) return '';

  return retreatType.replace('retreat_', '');
};

export const getRetreatCategorySlug = (retreatType: string) => {
  return `/retreats/${getRetreatTypeValue(retreatType).replaceAll('_', '-')}`;
};

export const findLocationByLocationParam = (
  locations: VenueLocationResponse[],
  location: string
) => {
  return locations.find(l =>
    l.location_name
      .toLocaleLowerCase()
      .replaceAll(' ', '-')
      .startsWith(location)
  );
};

export const convertLocationToSlug = (location: string) => {
  return location.toLowerCase().replaceAll(' ', '-');
};

export const getRetreatCategoryAndLocationSlug = (
  category: string,
  location: string
) => {
  return `${getRetreatCategorySlug(category)}/${convertLocationToSlug(
    location
  )}`;
};

export const getRetreatValueBySlug = (slug: string) => {
  return slug.replaceAll('-', '_');
};

export const getRetreatHeaderTitle = (category?: string, location?: string) => {
  if (!category && !location) return 'Insight Timer Retreats';

  return `${getRetreatType(category ?? '').replace('Retreats', '')} Retreats ${
    location
      ? `in ${location === 'United States' ? `the United States` : location}`
      : ''
  }`;
};

export const getRetreatType = (retreatType?: string) => {
  if (!retreatType) return '';

  return startCase(
    retreatType.replace('retreat_', '').replaceAll('_', ' ')
  ).replace('And', 'and');
};

export const getAtmosphereText = (atmosphere: string): string => {
  return (
    ATMOSPHERE_FILTERS.find(filter => filter.value === atmosphere)?.title ?? ''
  );
};

export const getLanguageText = (language: string): string => {
  return LANGUAGES_LIST[language] ?? '';
};

export const getDietText = (meal: string): string => {
  return DIETS_FILTERS.find(filter => filter.value === meal)?.title ?? '';
};

export const getAmenityText = (amenity: string): string => {
  return AMENETIES_FILTER.find(filter => filter.value === amenity)?.title ?? '';
};

export const generateDateFilter = (
  month: number | null,
  year: number | null
) => {
  if (!month || !year) {
    return {
      startDate: undefined,
      endDate: undefined
    };
  }

  const selectedDate = dayjs()
    .set('month', month - 1)
    .set('year', year);

  return {
    startDate: selectedDate.startOf('month').valueOf(),
    endDate: selectedDate.endOf('month').valueOf()
  };
};

export const DURATIONS = [
  {
    value: 'Any',
    minDurationInDays: undefined,
    maxDurationInDays: undefined
  },
  {
    value: 'Few days',
    minDurationInDays: 2,
    maxDurationInDays: 4
  },
  {
    value: '1 week',
    minDurationInDays: 5,
    maxDurationInDays: 9
  },
  {
    value: '2 weeks',
    minDurationInDays: 10,
    maxDurationInDays: undefined
  }
];

export const generateDurationFilter = (duration: string) => {
  const { minDurationInDays, maxDurationInDays } =
    DURATIONS.find(d => d.value === duration) ?? {};

  return {
    minDurationInDays,
    maxDurationInDays
  };
};

export const generateDateValue = (
  month: number | null,
  year: number | null,
  duration: string
) => {
  if (year && month) {
    const selectedDate = dayjs()
      .set('month', month - 1)
      .set('year', year);

    const date = selectedDate.format('MMMM');

    if (duration !== 'Any') return `${duration} in ${date}`;

    return date;
  }

  if (duration !== 'Any') {
    return duration;
  }

  return '';
};

interface GenerateDurationFilterParams {
  minDurationInDays?: number;
  maxDurationInDays?: number;
  startDate?: number;
  endDate?: number;
}

export const generateDateDurationFilter = ({
  minDurationInDays,
  maxDurationInDays,
  startDate,
  endDate
}: GenerateDurationFilterParams) => {
  const duration =
    DURATIONS.find(
      d =>
        d.maxDurationInDays === maxDurationInDays &&
        d.minDurationInDays === minDurationInDays
    )?.value ?? 'Any';

  let month: number | null = null;
  let year: number | null = null;

  if (startDate && endDate) {
    month = dayjs(startDate).get('month') + 1;
    year = dayjs(startDate).get('year');
  }

  return { month, year, duration };
};

export const generateDateDurationFilterText = (
  startDate?: number,
  minDuration?: number
) => {
  const startDateText = startDate ? dayjs(startDate).format('MMMM') : '';

  const durationText = minDuration
    ? minDuration === 2
      ? 'Few days'
      : minDuration === 5
      ? '1 week'
      : '2 weeks'
    : '';

  return `${durationText} in ${startDateText} `;
};

export const getRetreatCategoryFilterOnMobile = (categories: string[]) => {
  if (categories.length === 0) return '';

  const retreatCategory = getRetreatType(first(categories) ?? '');

  if (categories.length === 1) return retreatCategory;

  return `${retreatCategory} + ${categories.length - 1} more`;
};

export const getBalanceDueDate = (
  startDate?: number,
  balanceDueDays?: number
) => {
  const balanceDueDate = dayjs(startDate).subtract(balanceDueDays ?? 0, 'days');

  if (balanceDueDate.isBefore(dayjs())) return 'in 48 hours';

  return balanceDueDate.format('MMMM D, YYYY');
};

export const getRoomTypeAndOccupancy = ({
  roomType,
  priceType,
  maxOccupancy,
  isNoAccommodation,
  isNoAccommodationRetreat
}: {
  roomType?: AccommodationOptionType;
  priceType?: PriceType;
  maxOccupancy?: number;
  isNoAccommodation?: boolean;
  isNoAccommodationRetreat?: boolean;
}) => {
  if (isNoAccommodationRetreat) {
    return 'Participants to organize their own lodging or commuting.';
  }

  if (isNoAccommodation) {
    return 'For those who prefer to handle their own lodging arrangements';
  }

  if (roomType === 'PRIVATE_ROOM') {
    return `Private room for ${priceType === 'PER_ROOM' ? maxOccupancy : 1}`;
  }
  return `${maxOccupancy}-person shared room`;
};

export const getRoomPricePerItem = ({
  price,
  maxOccupancy
}: {
  price: Price;
  maxOccupancy: number;
}) => {
  const perItemPrice: Price =
    price.type === 'PER_ROOM'
      ? {
          price: price.price / maxOccupancy,
          currency: price.currency
        }
      : price;

  return getPriceWithCurrency(perItemPrice);
};

export const getRoomPrice = ({
  price,
  maxOccupancy,
  isSharedRoom
}: {
  price: Price;
  maxOccupancy: number;
  isSharedRoom: boolean;
}) => {
  const getPriceType =
    (maxOccupancy > 1 && price.type === 'PER_ROOM') || isSharedRoom
      ? '/ person'
      : 'total';

  const totalPrice =
    maxOccupancy > 1 && price.type === 'PER_ROOM'
      ? {
          price: price.price / maxOccupancy,
          currency: price.currency
        }
      : price;

  return `${getPriceWithCurrency(totalPrice)} ${getPriceType}`;
};

export const getNumberOfGuestString = (maxOccupancy: number) => {
  return maxOccupancy > 1 ? `${maxOccupancy} adults` : `${maxOccupancy} adult`;
};

export const getRetreatThumbail = ({
  ownerId,
  productId,
  orientation,
  size
}: {
  ownerId: string;
  productId: string;
  orientation: 'rectangle' | 'square' | 'portrait';
  size: 'small' | 'medium' | 'large' | 'xlarge';
}) => {
  return `${process.env.REACT_APP_WORKSHOP_HOST}/${ownerId}/marketplace/shop/products/${productId}/images/hero/tiny_${orientation}_${size}.jpeg`;
};

export const regenerateRetreatImage = ({
  url,
  orientation,
  size
}: {
  url: string;
  orientation: 'rectangle' | 'square' | 'portrait';
  size: 'small' | 'medium' | 'large' | 'xlarge';
}) => {
  const urlSegments = url.split('/');
  urlSegments[urlSegments.length - 1] = `${orientation}_${size}.jpeg`;
  return urlSegments.join('/');
};

export const getRetreatDurationInDays = (
  startDate: number,
  endDate: number
) => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);

  return `${end.diff(start, 'day') + 1} Days`;
};

export const getNoAccommodationPrice = (
  shopProduct: ShopProductSummary
): Price | null => {
  if (!shopProduct || !shopProduct.no_accommodation) return null;

  return {
    currency: 'USD',
    price: shopProduct.no_accommodation.price_per_person
  };
};

export const getNoAccommodationOption = (
  shopProduct: ShopProductSummary
): AccommodationOption | undefined => {
  if (!shopProduct || !shopProduct.no_accommodation) return undefined;

  const retreatImage = getRetreatThumbail({
    productId: shopProduct.id,
    ownerId: shopProduct.owner.id,
    orientation: 'square',
    size: 'medium'
  });

  const accommodationPrice = getNoAccommodationPrice(shopProduct);

  return {
    id: 'NO_ACCOMMODATION',
    name: 'Retreat Access without Accommodation',
    image_paths: [retreatImage],
    prices: accommodationPrice ? [accommodationPrice] : [],
    maximum_occupancy_per_room: 1,
    accommodation_option_type: 'PRIVATE_ROOM'
  };
};
