import { ChevronDownIcon } from '@chakra-ui/icons';
import { HStack, Icon, Text } from '@chakra-ui/react';
import React from 'react';

interface Props {
  isOpen: boolean;
  onToggle: () => void;
}

const ShowMore = ({ isOpen, onToggle }: Props) => {
  return (
    <HStack spacing={0} onClick={onToggle} cursor="pointer">
      <Text
        fontSize={16}
        fontWeight={450}
        textDecoration="underline"
        color="neutral.700"
      >
        Show {isOpen ? 'less' : 'more'}
      </Text>
      <Icon
        as={ChevronDownIcon}
        boxSize={4}
        transform={`rotate(${isOpen ? '180deg' : '0deg'})`}
      />
    </HStack>
  );
};

export default ShowMore;
