import { Text, TextProps } from '@chakra-ui/react';
import React from 'react';

export default function P2Regular({ children, ...props }: TextProps) {
  return (
    <Text
      fontSize="14px"
      lineHeight="19px"
      color="neutral.700"
      letterSpacing="-1%"
      whiteSpace="pre-wrap"
      fontWeight={390}
      {...props}
    >
      {children}
    </Text>
  );
}
