import { VStack } from '@chakra-ui/react';
import { useAnalytics } from 'hooks/useAnalytics';
import { GA_CLICK_EVENT_NAMES } from 'lib/firebase/ga_events';
import React from 'react';
import { getAtmosphereText } from 'services/shops/helper';
import { Atmosphere } from 'services/shops/interface';
import { useRetreatDiscovery } from 'views_2/RetreatsDiscovery/hooks/useRetreatDiscovery';
import CheckboxFilter from '../CheckboxFilter';
import LeftFilterBase from '../LeftFilterBase';
import RetreatsDivider from '../RetreatsDivider';

export const ATMOSPHERE_FILTERS = [
  {
    value: Atmosphere.FOR_SOLO_TRAVELERS,
    title: 'For Solo Travelers'
  },
  {
    value: Atmosphere.LGBTQIA_FRIENDLY,
    title: 'LGBTQIA+ Friendly'
  },
  {
    value: Atmosphere.WOMEN_ONLY,
    title: 'Woman Only'
  },
  {
    value: Atmosphere.MEN_ONLY,
    title: 'Men Only'
  },
  {
    value: Atmosphere.COUPLES_ONLY,
    title: 'Couples Only'
  },
  {
    value: Atmosphere.MOTHER_DAUGHTER,
    title: 'Mother-Daughter'
  },
  {
    value: Atmosphere.FAMILY_FRIENDLY,
    title: 'Family Friendly'
  }
];

const AtmosphereFilter = () => {
  const { trackGAClickEvent } = useAnalytics();
  const {
    filter,
    shopProductStats,
    onChangeCheckboxFilter
  } = useRetreatDiscovery();

  const atmospheres = ATMOSPHERE_FILTERS.map(atmosphere => {
    const noOfResults = shopProductStats?.atmospheres?.find(
      stats => stats.tag === `retreat_atmospheres_${atmosphere.value}`
    )?.count;

    return {
      ...atmosphere,
      noOfResults: noOfResults ?? 0
    };
  })
    .filter(atmosphere => atmosphere.noOfResults > 0)
    .sort((a, b) => b.noOfResults - a.noOfResults);

  const onOptionClick = (value: Atmosphere, checked: boolean) => {
    const beforeClickState = filter?.atmospheres?.includes(value) ?? false;
    trackGAClickEvent({
      click_event_name: GA_CLICK_EVENT_NAMES.SIDEBAR_FILTER_OPTION_CLICKED,
      page_name: 'retreats_search_page',
      filter_type: 'atmosphere',
      before_click_state: beforeClickState,
      option_name: getAtmosphereText(value)
    });

    onChangeCheckboxFilter('atmospheres', checked, value);
  };

  if (atmospheres.length === 0) return null;

  return (
    <>
      <LeftFilterBase
        title="Atmosphere"
        isShowMore={atmospheres.length > 5}
        filterType="atmosphere"
      >
        <VStack
          width="100%"
          alignItems="flex-start"
          spacing={{ base: '24px', lg: 3 }}
        >
          {atmospheres.map(atmosphere => (
            <CheckboxFilter
              key={atmosphere.value}
              title={atmosphere.title}
              isChecked={filter?.atmospheres?.includes(atmosphere.value)}
              noOfResults={atmosphere.noOfResults}
              onChange={event =>
                onOptionClick(atmosphere.value, event.target.checked)
              }
            />
          ))}
        </VStack>
      </LeftFilterBase>
      <RetreatsDivider />
    </>
  );
};

export default AtmosphereFilter;
