import { Heading, Text, VStack } from '@chakra-ui/react';
import React from 'react';

const ReconnectWithYourself = () => {
  return (
    <VStack
      align="start"
      width={{ base: '100%', lg: 120, xl: 210 }}
      spacing={2}
      flexShrink={0}
      pb={20}
    >
      <Heading fontSize={18} as="h2" fontFamily="ProximaNova">
        Find Your Perfect Retreat
      </Heading>
      <Text fontSize={16} fontFamily="ProximaNova">
        Discover transformative Retreats led by Insight Timer&apos;s best
        teachers.
        <Text as="span" mt={2} display="block">
          Reconnect, recharge, and return renewed.
        </Text>
      </Text>
    </VStack>
  );
};

export default ReconnectWithYourself;
