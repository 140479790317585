import { VStack } from '@chakra-ui/react';
import { useAnalytics } from 'hooks/useAnalytics';
import { GA_CLICK_EVENT_NAMES } from 'lib/firebase/ga_events';
import React from 'react';
import { getDietText } from 'services/shops/helper';
import { Diet } from 'services/shops/interface';
import { useRetreatDiscovery } from 'views_2/RetreatsDiscovery/hooks/useRetreatDiscovery';
import CheckboxFilter from '../CheckboxFilter';
import LeftFilterBase from '../LeftFilterBase';
import RetreatsDivider from '../RetreatsDivider';

export const DIETS_FILTERS = [
  {
    value: Diet.VEGAN,
    title: 'Vegan'
  },
  {
    value: Diet.VEGETARIAN,
    title: 'Vegetarian'
  },
  {
    value: Diet.HALAL,
    title: 'Halal'
  },
  {
    value: Diet.KOSHER,
    title: 'Kosher'
  },
  {
    value: Diet.GLUTEN_FREE,
    title: 'Gluten-free'
  },
  {
    value: Diet.DAIRY_FREE,
    title: 'Diary-free'
  },
  {
    value: Diet.RAW,
    title: 'Raw'
  },
  {
    value: Diet.ORGANIC,
    title: 'Organic'
  },
  {
    value: Diet.NUT_ALLERGY_FRIENDLY,
    title: 'Nut Allergy Friendly'
  },
  {
    value: Diet.DIABETIC_FRIENDLY,
    title: 'Diabetic Friendly'
  },
  {
    value: Diet.KETO_FRIENDLY,
    title: 'Keto Friendly'
  },
  {
    value: Diet.PALEO_FRIENDLY,
    title: 'Paleo Friendly'
  }
];

const DietFilter = () => {
  const { trackGAClickEvent } = useAnalytics();
  const {
    filter,
    shopProductStats,
    onChangeCheckboxFilter
  } = useRetreatDiscovery();

  const diets = DIETS_FILTERS.map(diet => {
    const noOfResults = shopProductStats?.diets.find(
      stats => stats.tag === `retreat_diets_${diet.value}`
    )?.count;

    return {
      ...diet,
      noOfResults: noOfResults ?? 0
    };
  })
    .filter(diet => diet.noOfResults > 0)
    .sort((a, b) => b.noOfResults - a.noOfResults);

  const onOptionClick = (value: Diet, checked: boolean) => {
    const beforeClickState = filter?.diets?.includes(value) ?? false;
    trackGAClickEvent({
      click_event_name: GA_CLICK_EVENT_NAMES.SIDEBAR_FILTER_OPTION_CLICKED,
      page_name: 'retreats_search_page',
      filter_type: 'diet',
      before_click_state: beforeClickState,
      option_name: getDietText(value)
    });

    onChangeCheckboxFilter('diets', checked, value);
  };

  if (diets.length === 0) return null;

  return (
    <>
      <LeftFilterBase
        title="Diet"
        isShowMore={diets.length > 5}
        filterType="diet"
      >
        <VStack
          width="100%"
          alignItems="flex-start"
          spacing={{ base: '24px', lg: 3 }}
        >
          {diets.map(diet => (
            <CheckboxFilter
              key={diet.value}
              title={diet.title}
              isChecked={filter?.diets?.includes(diet.value)}
              noOfResults={diet.noOfResults}
              onChange={event =>
                onOptionClick(diet.value, event.target.checked)
              }
            />
          ))}
        </VStack>
      </LeftFilterBase>
      <RetreatsDivider />
    </>
  );
};

export default DietFilter;
