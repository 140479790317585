import queryString from 'query-string';
import useSWR from 'swr';
import { ShopProductFilter } from '../interface';
import { getShopProductStats } from '../queries';

const useShopProductStats = (params: ShopProductFilter, shouldFetch = true) => {
  const query = queryString.stringify(params, { arrayFormat: 'comma' });

  const { data, isValidating } = useSWR(
    shouldFetch ? `/shop-products/stats${query}` : null,
    () => getShopProductStats(params),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  );

  return {
    shopProductStats: data,
    isLoading: isValidating
  };
};

export default useShopProductStats;
