import { Checkbox, CheckboxProps, Flex, HStack } from '@chakra-ui/react';
import useBreakpoint from 'hooks/useBreakpoint';
import React from 'react';
import P2Regular from '../../Retreats/components/typography/P2Regular';
import P3Regular from '../../Retreats/components/typography/P3Regular';

interface Props extends CheckboxProps {
  title: string;
  noOfResults?: number;
  variant?: 'default' | 'greyed-out';
}

const CheckboxFilter = ({
  title,
  noOfResults,
  variant = 'default',
  ...props
}: Props) => {
  const { isLgScreen } = useBreakpoint();

  return (
    <HStack width="100%">
      <Checkbox
        display="flex"
        flexDirection={{ base: 'row-reverse', lg: 'row' }}
        justifyContent={{ base: 'space-between', lg: 'flex-start' }}
        spacing={{ base: 0, lg: '15px' }}
        size="lg"
        width="100%"
        variant="retreats-filter"
        _checked={{
          '& .chakra-checkbox__control': {
            background: 'neutral.700',
            borderColor: 'neutral.700'
          }
        }}
        {...props}
      >
        <Flex
          justifyContent="space-between"
          width="100%"
          alignItems="center"
          cursor="pointer"
        >
          <P2Regular
            noOfLines={1}
            fontSize={{ base: 16, lg: 14 }}
            color={variant === 'greyed-out' ? 'neutral.400' : 'neutral.700'}
          >
            {title}
          </P2Regular>
          {isLgScreen && (
            <P3Regular
              color="neutral.400"
              pl={2}
              fontWeight={props.isChecked ? 'bold' : 'normal'}
            >
              {noOfResults}
            </P3Regular>
          )}
        </Flex>
      </Checkbox>
    </HStack>
  );
};

export default CheckboxFilter;
