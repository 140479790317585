// Material ui
/* eslint-disable import/extensions,no-console,global-require */
import { ChakraProvider } from '@chakra-ui/react';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from 'common/theme';
import { LiveEventPreviewProvider } from 'components/LiveEventPreview/useLiveEventPreview';
import setCssScrollBarWidthCustomVar from 'helpers/setCssScrollBarWidthCustomVar';
import { FirebaseAuthProvider } from 'hooks/useFirebaseAuth';
import { GlobalStatsProvider } from 'hooks/useGlobalStats';
import I18nWrapper from 'hooks/useI18n';
import usePrerender from 'hooks/usePrerender';
import { UserSubscriberProvider } from 'hooks/useUserSubscriber';
import initClarity from 'lib/clarity';
import initializePerformanceMonitoring from 'lib/firebase/firebase_performance';
import OsanoScript from 'lib/osano';
import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'swiper/css/swiper.min.css';
import MaterialUiProvider from './Assets/Themes/MaterialUiProvider';
import './index.css';
import Routes from './Routes';
import './styles/main.scss';
import './tailwind.css';

const App: React.FC = () => {
  const { isPrerender } = usePrerender();
  useEffect(() => {
    initClarity();
    initializePerformanceMonitoring();
    setCssScrollBarWidthCustomVar();
  }, []);

  const emotionCache = createCache({
    key: 'insight',
    speedy: !isPrerender
  });

  useEffect(() => {
    let timeoutJob: number;
    if (isPrerender) {
      timeoutJob = setTimeout(() => {
        window.prerenderReady = true;
      }, 40000); // max prerender timeout 40s
    }
    return () => {
      clearTimeout(timeoutJob);
    };
  }, [isPrerender]);

  return (
    <div className="bg-white">
      <OsanoScript />
      <I18nWrapper>
        <CacheProvider value={emotionCache}>
          <ChakraProvider theme={theme}>
            <MaterialUiProvider>
              <CssBaseline />
              <GlobalStatsProvider>
                <FirebaseAuthProvider>
                  <UserSubscriberProvider>
                    <LiveEventPreviewProvider>
                      <Routes />
                    </LiveEventPreviewProvider>
                  </UserSubscriberProvider>
                </FirebaseAuthProvider>
              </GlobalStatsProvider>
            </MaterialUiProvider>
          </ChakraProvider>
        </CacheProvider>
      </I18nWrapper>
      <ToastContainer />
    </div>
  );
};

export default App;
