import { useDisclosure } from '@chakra-ui/react';
import { VIEWS } from 'components/AuthModal/commonTypes';
import noop from 'lodash/noop';
import React, {
  lazy,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { AuthState, useFirebaseAuth } from './useFirebaseAuth';

const AuthModal = lazy(() => import('components/AuthModal'));

const OnboardingModal = lazy(() =>
  import('components/OnboardingQuiz/OnboardingModal')
);

type AuthSuccessCallbackFn = (currentScreen: VIEWS) => void;
type ModalCloseCallbackFn = () => void;

interface HideAuthPopupParams {
  ignoreCloseCallback?: boolean;
}

interface DisplayLoginPopupConfig {
  onSuccess?: AuthSuccessCallbackFn;
  onModalClose?: ModalCloseCallbackFn;
}

interface DisplaySignupPopupConfig {
  screen?: VIEWS;
  email?: string;
  hideSignUpWithAppleProvider?: boolean;
  onSuccess?: AuthSuccessCallbackFn;
  onModalClose?: ModalCloseCallbackFn;
}

export const AuthPopupContext = React.createContext<{
  showLoginPopup: (config?: DisplayLoginPopupConfig) => void;
  showSignupPopup: (config?: DisplaySignupPopupConfig) => void;
  setPrefillEmail: (email: string) => void;
  onOpenOnboardingModal: (reason?: string) => void;
  prefillEmail?: string;
}>({
  showLoginPopup: noop,
  showSignupPopup: noop,
  setPrefillEmail: noop,
  onOpenOnboardingModal: noop
});

export const AuthPopupProvider: React.FC = ({ children }) => {
  const [screen, setScreen] = useState<VIEWS | undefined>();
  const [hideSignUpWithApple, setHideSignUpWithApple] = useState(false);
  const { authState, user } = useFirebaseAuth();
  const [prefillEmail, setPrefillEmail] = useState<string>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [onboardingInitialReason, setOnboardingInitialReason] = useState<
    string
  >('');

  const [loadAuthModal, setLoadAuthModal] = useState(false);
  const [loadOnboardingModal, setLoadOnboardingModal] = useState(false);

  const {
    isOpen: isOpenOnboardingModal,
    onOpen: defaultOnOpenOnboardingModal,
    onClose: defaultOnCloseOnboardingModal
  } = useDisclosure();

  useEffect(() => {
    if (isOpen) setLoadAuthModal(true);
    if (isOpenOnboardingModal) setLoadOnboardingModal(true);
  }, [isOpen, isOpenOnboardingModal]);

  const onOpenOnboardingModal = useCallback(
    (reason?: string) => {
      if (reason) {
        setOnboardingInitialReason(reason);
      } else {
        setOnboardingInitialReason('');
      }

      defaultOnOpenOnboardingModal();
    },
    [defaultOnOpenOnboardingModal]
  );

  const onCloseOnboardingModal = useCallback(() => {
    defaultOnCloseOnboardingModal();
  }, [defaultOnCloseOnboardingModal]);

  const [authSuccessCallback, setAuthSuccessCallback] = useState<
    AuthSuccessCallbackFn
  >();

  const [modalCloseCallback, setModalCloseCallback] = useState<
    ModalCloseCallbackFn
  >();

  const displayLoginPopup = useCallback(
    (config?: DisplayLoginPopupConfig) => {
      const { onSuccess, onModalClose } = config || {};

      setAuthSuccessCallback(() => {
        return onSuccess;
      });

      setModalCloseCallback(() => {
        return onModalClose;
      });

      setScreen('LOGIN');
      onOpen();
    },
    [onOpen]
  );

  const displaySignupPopup = useCallback(
    (config?: DisplaySignupPopupConfig) => {
      const {
        screen,
        email,
        hideSignUpWithAppleProvider,
        onSuccess,
        onModalClose
      } = config || {};

      setHideSignUpWithApple(!!hideSignUpWithAppleProvider);

      setPrefillEmail(email);

      setAuthSuccessCallback(() => {
        return onSuccess;
      });

      setModalCloseCallback(() => {
        return onModalClose;
      });

      setScreen(screen || 'SIGN_UP_OPTIONS_VIEW');
      onOpen();
    },
    [onOpen]
  );

  const hideAuthPopup = useCallback(
    (config?: HideAuthPopupParams) => {
      const { ignoreCloseCallback = false } = config || {};

      onClose();
      setScreen(undefined);
      setAuthSuccessCallback(undefined);

      if (!ignoreCloseCallback && modalCloseCallback) {
        modalCloseCallback();
      }
      setModalCloseCallback(undefined);
    },
    [onClose, modalCloseCallback]
  );

  useEffect(() => {
    if (!screen) return; // Do nothing if screen / popup is not open
    if (authState === AuthState.AUTHORIZED && !user?.isAnonymous) {
      if (authSuccessCallback) {
        authSuccessCallback(screen);
      }

      hideAuthPopup({ ignoreCloseCallback: true });
    }
  }, [authState, hideAuthPopup, authSuccessCallback, screen, user]);

  return (
    <AuthPopupContext.Provider
      value={{
        showLoginPopup: displayLoginPopup,
        showSignupPopup: displaySignupPopup,
        setPrefillEmail,
        onOpenOnboardingModal,
        prefillEmail
      }}
    >
      <Suspense fallback={<></>}>
        {loadAuthModal && (
          <AuthModal
            open={isOpen}
            startScreen={screen}
            hideSignUpWithAppleBtn={hideSignUpWithApple}
            setScreen={setScreen}
            onClose={hideAuthPopup}
          />
        )}
        {loadOnboardingModal && (
          <OnboardingModal
            isOpen={isOpenOnboardingModal}
            onClose={onCloseOnboardingModal}
            initialReason={onboardingInitialReason}
          />
        )}
      </Suspense>

      {children}
    </AuthPopupContext.Provider>
  );
};

export const useAuthPopup = () => useContext(AuthPopupContext);
