import { Box, BoxProps, HStack, Link, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import getPriceWithCurrency, {
  createPlaceName
} from 'services/retreats/helpers';
import {
  getCheapestPriceFromShopProduct,
  getRetreatThumbail,
  retreatDurationText
} from 'services/shops/helper';
import { ShopProductSummary } from 'services/shops/interface';

interface Props extends BoxProps {
  shopProduct?: ShopProductSummary | null;
}

const RetreatCardSmall = ({ shopProduct }: Props) => {
  if (!shopProduct) return null;

  const place = createPlaceName(
    shopProduct?.retreat_venue?.region.city,
    shopProduct?.retreat_venue?.region.state,
    shopProduct?.retreat_venue?.region.country
  );

  return (
    <Link
      width="100%"
      height="100%"
      borderRadius={12}
      boxShadow="0 2px 10px 0 rgba(0,0,0,0.1)"
      overflow="hidden"
      href={`/retreat/${shopProduct.slug}`}
      target="_blank"
      display="flex"
      flexDirection="column"
      _hover={{ textDecoration: 'none' }}
    >
      <Box
        backgroundImage={getRetreatThumbail({
          productId: shopProduct.id,
          ownerId: shopProduct.owner.id,
          orientation: 'rectangle',
          size: 'large'
        })}
        backgroundSize="cover"
        backgroundPosition="center"
        paddingTop="56.25%"
      />
      <VStack spacing={2} p={4} alignItems="flex-start" flex={1}>
        <VStack alignItems="flex-start" flex={1}>
          <Text
            fontSize="18px"
            lineHeight="22px"
            fontFamily="gelica"
            noOfLines={2}
          >
            {shopProduct.title}
          </Text>
          <Text fontSize={12} lineHeight="18px">
            With {shopProduct.owner.name}
          </Text>
        </VStack>
        <Box width="100%" height="1px" background="neutral.300" />
        <HStack spacing={3} width="100% ">
          <Text fontSize={12} lineHeight="18px" color="neutral.600">
            From <br />
            {getPriceWithCurrency(
              getCheapestPriceFromShopProduct(shopProduct),
              false
            )}
          </Text>
          <Box width="1px" height="44px" background="neutral.300" />
          <Text color="neutral.600" flex={1}>
            <Text as="span" noOfLines={1} fontSize={12} lineHeight="18px">
              {retreatDurationText(
                shopProduct.start_date,
                shopProduct.end_date
              )}
            </Text>
            <Text as="span" noOfLines={1} fontSize={12} lineHeight="18px">
              {place}
            </Text>
          </Text>
        </HStack>
      </VStack>
    </Link>
  );
};

export default RetreatCardSmall;
