import { useEffect, useRef } from 'react';

function usePrevious<T>(value: T, skipUndefined = false) {
  const ref = useRef<T>();
  useEffect(() => {
    if (!skipUndefined || value !== undefined) ref.current = value;
  });
  return ref.current;
}

export default usePrevious;
